<template>
  <div>
    <vue-pdf-app
      :class="Object.keys(userData).length > 0 ? 'alturaPdf' : 'alturaPdfGuest'"
      :pdf="arquivoPdf"
      :config="config"
    ></vue-pdf-app>
  </div>
</template>

<script>
import arquivoPdf from "@/assets/politica-privacidade/politicaDePrivacidade.pdf";
import { getUserData } from "@/auth/utils";

export default {
  title: "Política de Privacidade",
  data() {
    return {
      arquivoPdf,
      config: {
        toolbar: false,
      },
      userData: getUserData(),
    };
  },

  mounted() {
    if (Object.keys(this.userData).length > 0) {
      this.$router.push({ name: "politica-privacidade" }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    }
  },
};
</script>
<style scoped>
.alturaPdf {
  height: 86vh;
}
.alturaPdfGuest {
  height: 100vh;
}
.pdf-app.light {
  --pdf-app-background-color: #f8f8f8;
}
</style>
